<template>
    <div>
        <div id="fullcontent">
            <router-view />
        </div>
    </div>
</template>

<script>
import { Api } from '@/core/api.js'
const api = new Api()

export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    created() {
        //this.$bar.hide()

        // PORTRAIT LOCK
        if (['portrait'].includes(this.$mq) && this.$store.getters.getIsApp) {
            screen.orientation.lock('portrait')
        }
    },
    destroyed() {
        // ORIENTATION UNLOCK
        screen.orientation.unlock()
    }
}
</script>

<style lang="scss">
#fullcontent.inLogin {
    @include display-flex();
    @include justify-content();
    @include align-items();
    width: 100%;
    height: 100%;
    z-index: $z-content;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $default-bg-color;
}
</style>
